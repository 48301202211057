import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";

export default function OtherLinkView() {
  return (
      <div className="menu-item-link">
        <ul className="list-unstyled">
          <li>
            <a href="http://dienbien.gov.vn/portal/Pages/default.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Cổng thông tin điện tử
            </a>
          </li>
          <li>
            <a href="https://dienbientv.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Truyền hình Điện Biên
            </a>
          </li>
          <li>
            <a href="https://dienbientv.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faVideo} />
                </span>
              Phát thanh Điện Biên
            </a>
          </li>
          <li>
            <a href="https://dienbientv.vn/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faNewspaper} />
                </span>
              Báo Điện Biên
            </a>
          </li>
          <li>
            <a href="https://dienbientv.vn/du-lich/gioi-thieu-du-lich-dien-bien/" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEye} />
                </span>
              Du lịch Điện Biên
            </a>
          </li>
          <li>
            <a href="http://dienbien.gov.vn/portal/Pages/lich-lam-viec.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
              Lịch công tác
            </a>
          </li>
          <li>
            <a href="http://dienbien.gov.vn/portal/faq/Pages/chuyen-trang-hoi-dap.aspx" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              Hỏi đáp trực tuyến
            </a>
          </li>
          <li>
            <a href="http://qppl.dienbien.gov.vn/qlvb/vbpq.nsf/DefaultMetro?OpenForm" target="_blank" >
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              Văn bản pháp quy
            </a>
          </li>
          <li>
            <a href="http://qppl.dienbien.gov.vn/qlvb/vbpq.nsf/DefaultMetro?OpenForm" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faBook} />
                </span>
              VB chỉ đạo điều hành
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/forms/d/1dVRyq7E3H9c_BwG1_wOzUAFWsao_sbcn1s0L7TFrLIQ/viewform?edit_requested=true" target="_blank">
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              Hòm thư góp ý
            </a>
          </li>
        </ul>
      </div>
  );
}
